<template lang="">
    <div>
        <div v-html="contentDescription"></div>
        <p>
            {{ warningText }}
            <a :href="warningUrl" target="_blank">zde.</a>
        </p>
        <button class="btn btn-primary mb-3" @click="showThirdPartyContent">
            {{ warningButtonText }}
        </button>
        <div v-html="thirdPartyContent" v-if="visible"></div>
        <div v-html="contentDescription2"></div>
    </div>
</template>
<script>
export default {
    name: "ThirdPartyContent",
    props: ["warning", "content"],
    data() {
        return {
            visible: false
        };
    },
    computed: {
        contentDescription() {
            return this.content.split(
                this.content.slice(
                    this.content.search("<iframe"),
                    this.content.search("</iframe>") + 9
                )
            )[0];
        },
        thirdPartyContent() {
            return this.content.slice(
                this.content.search("<iframe"),
                this.content.search("</iframe>") + 9
            );
        },
        contentDescription2() {
            return this.content.split(
                this.content.slice(
                    this.content.search("<iframe"),
                    this.content.search("</iframe>") + 9
                )
            )[1];
        },
        warningText() {
            if (this.content.includes("//api.mapy.cz/frame")) {
                return " Pokud chcete zobrazit mapy seznam.cz přímo na stránce, je potřeba povolit používání osobních dat stisknutím tlačítka Povolit. Více informací si můžete přečíst";
            } else if (
                this.content.includes("//calendar.google.com/calendar/embed")
            ) {
                return "Pokud chcete zobrazit google kalendář přímo na stránce, je potřeba povolit používání osobních dat stisknutím tlačítka Povolit. Více informací si můžete přečíst";
            }
        },
        warningUrl() {
            if (this.content.includes("//api.mapy.cz/frame")) {
                return "https://o.seznam.cz/personalizace-obsahu-a-reklamy";
            } else if (
                this.content.includes("//calendar.google.com/calendar/embed")
            ) {
                return "https://policies.google.com/privacy";
            }
        },
        warningButtonText() {
            if (this.content.includes("//api.mapy.cz/frame")) {
                return "Povolit";
            } else if (
                this.content.includes("//calendar.google.com/calendar/embed")
            ) {
                return "Povolit";
            }
        }
    },
    mounted() {},
    methods: {
        showThirdPartyContent() {
            this.visible = true;
        }
    }
};
</script>
