<template>
    <div class="gallery-container">
        <div
            class="gallery__item"
            v-for="post in posts.slice(0, 18)"
            :key="post.id"
        >
            <a :href="post.permalink" target="_blank"
                ><img class="gallery__img" :src="post.media_url" alt=""
            /></a>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            posts: [],
            postslimited: []
        };
    },

    mounted() {
        this.getPosts();
        this.limit();
    },
    computed: {
        ...mapGetters({
            appParams: "getAppParams"
        })
    },

    methods: {
        ...mapActions(["setPageParams"]),

        async getPosts() {
            axios.get("/instagram").then(res => {
                this.posts = res.data.data;
            });
        },
        limit() {
            for (
                let index = 0;
                index < this.posts.length && index < 3;
                index++
            ) {
                this.postslimited[index] = this.posts[index];
            }
        }
    }
};
</script>

