<template>
    <vc-calendar :attributes="attributes" locale="cs" :masks="masks" />
</template>

<script>
export default {
    data() {
        return {
            events: [],
            event: {
                title: null,
                date: null,
                time: null
            },

            masks: {
                dayPopover: "WWWW"
            }
        };
    },
    computed: {
        attributes() {
            return [
                ...this.events.map(event => ({
                    dates: event.dates,
                    dot: {
                        color: event.color,
                        class: event.isComplete ? "opacity-75" : ""
                    },
                    popover: {
                        label: event.description + " / " + event.time
                    },
                    customData: event
                }))
            ];
        }
    },
    created() {
        this.showEventCalendar();
    },
    methods: {
        async showEventCalendar() {
            await axios
                .get("/showEventCalendar")
                .then(response => {
                    this.events = response.data;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        }
    }
};
</script>
