<template>
    <div>
        <div class="card mb-3" id="itb-weather">
            <div class="card-body">
                <div class="card-title"><strong>Turnov</strong><br><span v-html="dateBuilder()"></span></div>
                <hr>
                <div class="row">
                    <div class="col-6 text-center" style="font-size: 45px; line-height: 40px; margin: auto; padding-top: 7px">
                        {{ temperatureConverter(weather.current ? weather.current.temp : 0) }}&deg;C
                        <div style="font-size: 25px">
                            {{ weather.current ? weather.current.weather[0].description : ""  }}
                        </div>
                    </div>
                    <div class="col-6"><img v-if="weather.current" :src="`https://openweathermap.org/img/wn/${weather.current.weather[0].icon}@2x.png`"></img></div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6">Rychlost větru</div>
                    <div class="col-6 text-right">{{ speedConverter(weather.current ? weather.current.wind_speed : "") }} km/h</div>
                </div>
                <div class="row">
                    <div class="col-6">Vlhkost</div>
                    <div class="col-6 text-right">{{ weather.current ? weather.current.humidity : ""  }}%</div>
                </div>
                <hr>
                <div class="row itb-next-days">
                    <div class="col-4">{{ dayBuilder(1) }}</div>
                    <div class="col-4 text-center position-relative"><img v-if="weather.daily" :src="`https://openweathermap.org/img/wn/${weather.daily[0].weather[0].icon}@2x.png`"></img></div>
                    <div class="col-4 text-right">{{ weather.daily ? Math.round(weather.daily[0].temp.max) : ""}}&deg;C/{{ weather.daily ? Math.round(weather.daily[0].temp.min) : ""}}&deg;C</div>
                </div>
                <div class="row itb-next-days">
                    <div class="col-4">{{ dayBuilder(2) }}</div>
                    <div class="col-4 text-center position-relative"><img v-if="weather.daily" :src="`https://openweathermap.org/img/wn/${weather.daily[1].weather[0].icon}@2x.png`"></img></div>
                    <div class="col-4 text-right">{{ weather.daily ? Math.round(weather.daily[1].temp.max) : ""}}&deg;C/{{ weather.daily ? Math.round(weather.daily[1].temp.min) : ""}}&deg;C</div>
                </div>
                <div class="row itb-next-days">
                    <div class="col-4">{{ dayBuilder(3) }}</div>
                    <div class="col-4 text-center position-relative"><img v-if="weather.daily" :src="`https://openweathermap.org/img/wn/${weather.daily[2].weather[0].icon}@2x.png`"></img></div>
                    <div class="col-4 text-right">{{ weather.daily ? Math.round(weather.daily[2].temp.max) : ""}}&deg;C/{{ weather.daily ? Math.round(weather.daily[2].temp.min) : ""}}&deg;C</div>
                </div>
            </div>
        </div>
        <a href="https://app.weathercloud.net/d0464474904#profile" target="_blank">Aktuální počasí z naší meteostanice</a>
    </div>
</template>

<script>
  export default {
    name: "WeatherWidget",
    data () {
      return {
        weather: {},
      }
    },
    mounted() {
        this.getPosts();
    },
    methods: {
        async getPosts() {
            axios.get("/weather").then(res => {
                this.weather = res.data;
            });
        },
        dateBuilder:function () {
            let d = new Date();
            let days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle', 'Pondělí', 'Úterý'];
            let months = ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec',
            'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'
            ];
            let day = days[d.getDay()];
            let month = months[d.getMonth()];
            let date = d.getDate();
            let year = d.getFullYear();
            let time = d.getHours() + ":" + d.getMinutes();
            return `${date}. ${month} ${year}<span style="margin: 0 10px; color: rgba(0, 0, 0, 0.3)">|</span>${day} ${time}`;
        },

        temperatureConverter:function (temp) {
            if(temp > -1 && temp < 0) {
                return parseFloat(temp * -1).toFixed(0);
            }else{
                return parseFloat(temp).toFixed(0);
            }
        },
        speedConverter:function (vel) {
            return parseFloat(((vel * 60 * 60) / 1000)).toFixed(0);
        },

        dayBuilder:function (times) {
            let d = new Date();
            let days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle', 'Pondělí', 'Úterý'];
            let day = days[d.getDay() + times];
            return day;
        },
    }
  }
</script>