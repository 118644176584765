<template>
    <div>
        <div v-if="message">
            <div
                :class="'alert alert-' + message.type"
                role="alert"
                v-if="message"
            >
                {{ message.text }}
            </div>
            <button
                type="button"
                class="btn btn-primary"
                @click.prevent="message = null"
            >
                Nový požadavek
            </button>
        </div>
        <form v-if="!message" class="d-flex" id="form" @submit.prevent="save">
            <input
                placeholder="Zadejte Váš e-mail"
                type="email"
                class="form-control mr-2"
                id="validationDefault07"
                v-model.trim="email"
                @input="$v.email.$touch()"
                :class="{
                    'itb-field-error': $v.email.$error
                }"
                ref="email"
            />
            <button
                type="submit"
                class="btn btn-primary"
                :disabled="submitStatus === 'PENDING'"
            >
                Potvrdit
            </button>
        </form>
        <div v-if="$v.email.$dirty">
            <p class="itb-error-message" v-if="!$v.email.email">
                <!-- Please enter a valid email address. -->
                Prosím zadejte platnou emailovou adresu
            </p>
            <p class="itb-error-message" v-if="!$v.email.required">
                <!-- E-mail must not be empty. -->
                E-mail nesmí být prázdný.
            </p>
        </div>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
    name: "NewsLetterForm",
    data() {
        return {
            valid: true,
            message: null,
            submitStatus: null,
            email: ""
        };
    },

    validations: {
        email: {
            required,
            email
        }
    },

    methods: {
        async save() {
            var toData = {
                email: this.email
            };
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.submitStatus = "ERROR";
                for (let key in Object.keys(this.$v)) {
                    const input = Object.keys(this.$v)[key];
                    if (input.includes("$")) return false;
                    if (this.$v[input].$error) {
                        this.$refs[input].scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                        });
                        break;
                    }
                }
            } else {
                await axios
                    .post("/newsLetter", toData)
                    .then(response => {
                        this.message = {
                            text: "E-mail byl přihlášen k odběru newsletteru",
                            type: "success"
                        };
                        this.$v.$reset();
                        this.email = "";
                        setTimeout(function() {
                            this.message = null;
                        }, 3000);
                    })
                    .catch(error => {
                        console.log(error);

                        this.message = {
                            text: "Bohužel došlo k chybě, zkuste znovu prosím",
                            type: "danger"
                        };

                        setTimeout(function() {
                            this.message = null;
                        }, 3000);
                    });
                this.submitStatus = "PENDING";
                setTimeout(() => {
                    this.submitStatus = "OK";
                }, 500);
            }
        }
    }
};
</script>

<style>
.itb-error-message {
    font-size: 80%;
    color: #f04124;
    margin-bottom: 4px;
}
.itb-label-error {
    color: #f04124;
}
.itb-field-error {
    border-color: #f79483;
    color: #f04124;
}
</style>
